angular
  .module('starter.business.controllers')
  .controller('settingsCtrlV2', function ($scope, $rootScope, $window, $ngRedux, $state, $stateParams) {
    $scope.user = {}
    $scope.company = {}
    $scope.iFrameStyle = $rootScope.isOverlayVisible ? 'w-full h-full relative z-modal' : 'w-full h-full relative'
    $rootScope.$watch('isOverlayVisible', () => {
      $scope.iFrameStyle = $rootScope.isOverlayVisible ? 'w-full h-full relative z-modal' : 'w-full h-full relative'
    })
    $ngRedux.connect((state) => state.app.user || {})($scope.user)
    $ngRedux.connect((state) => state.app.company || {})($scope.company)

    $scope.isLoading = true
    $scope.token = $window.localStorage.pr_token
    $scope.$watchGroup(['user.e', 'company.e'], () => {
      const e = $scope.user?.e || $scope.company?.e
      const settingRevampIframe = document.getElementById('settings-v2')
      settingRevampIframe.src = `${process.env.DEV_V2}/settings-page?isIframe=true&t=${$scope.token}&e=${e}&userType=employer&clientId=${$scope.company._id}`
      settingRevampIframe.onload = () => {
        $scope.isLoading = false
        $rootScope.$apply()
      }
    })
  })
