import { setModals } from '/reducers/appSlice'
const { postData } = require('@bookipi/utils')
const {
  setCurrentClient,
  setDisplayInviteClient,
  setDisplayAddSeats,
  setEmployeeMissing,
  setSubscriptionState
} = require('/src/reducers/subscriptionSlice')

angular
  .module('starter.agent.controllers')
  .controller(
    'agentClientsCtrl',
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      Http,
      $window,
      Service,
      CompanyAction,
      HttpPromiseAndLocalStorage,
      AgentClientInvitationModal,
      GLOBALS,
      Analytics,
      API_ENDPOINT,
      $ngRedux,
      Http2
    ) {
      delete $window.localStorage.pr_client_id
      delete $rootScope.user
      delete $rootScope.company
      delete $rootScope.employees
      delete $rootScope.payRuns
      delete $rootScope.leaves
      delete $rootScope.holidays
      $scope.missingEmployee = 0
      $scope.clientsInViewport = []
      $scope.company = {}
      $scope.modals = []
      $scope.isRemoveSubscriptionVisible = false
      $scope.shouldShowLoading =
        (process.env.PROJECT_VERSION === 'v2' && !!$stateParams.clientId) || !!$stateParams.redirect === 'fromV2'
      $ngRedux.connect((state) => state.app.company || {})($scope.company)
      $ngRedux.connect((state) => ({ modals: state.app.modals }))($scope)
      $ngRedux.connect((state) => ({ gbFeatures: state.app.gbFeatures }))($scope)

      $scope.isXeroImportOptIn = $scope.gbFeatures?.xero_import_opt_in

      $scope.$watch('modals', () => {
        if (!$scope.modals.length) {
          $scope.isRemoveSubscriptionVisible = false
          return
        }
        const isRemoveSubscriptionVisible = $scope.modals.every((modal) => modal.kind === 'RemoveSubscription')
        $scope.isRemoveSubscriptionVisible = isRemoveSubscriptionVisible
      })

      const isInViewport = function (ele) {
        const bounding = ele.getBoundingClientRect()
        return (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= ($window.innerHeight || $window.document.documentElement.clientHeight) &&
          bounding.right <= ($window.innerWidth || $window.document.documentElement.clientWidth)
        )
      }

      $scope.isLoading = false
      $scope.onOptIn = async () => {
        $scope.isLoading = true
        const UX_REVAMP_URL = process.env.DEV_V2
        const token = $window.localStorage.pr_token
        const expires = $window.localStorage.pr_expires
        const ENDPOINT = process.env.API_ENDPOINT
        await postData({
          url: `${ENDPOINT}/v1/company/update`,
          data: {
            company: {
              redirectToV2Web: true
            }
          }
        })
        $window.location.replace(`${UX_REVAMP_URL}/agent/clients?t=${token}&e=${expires}&userType=agent`)
        if (window !== undefined) window.localStorage.clear()
      }

      $scope.onToggleXeroImport = () => {
        $ngRedux.dispatch(setModals([{ kind: 'XeroOptIn' }]))
      }

      const calculateTrInViewport = _.debounce(
        () => {
          const clientsInViewport = [...$window.document.querySelectorAll('.tr-client')]
            .filter((tr) => isInViewport(tr))
            .map((tr) => tr.id.replace('client', ''))
          $scope.$apply(() => {
            $scope.clientsInViewport = clientsInViewport
          })
        },
        30,
        {
          leading: false,
          trailing: true
        }
      )

      const initTrInViewport = () => {
        calculateTrInViewport()
        if ($scope.clientsInViewport.length === 0) {
          setTimeout(initTrInViewport, 3000)
        }
      }
      setTimeout(initTrInViewport, 3000)

      $window.document.querySelector('.content-box').addEventListener('scroll', () => {
        calculateTrInViewport()
      })

      let handleDetect = $ngRedux.connect(mapStateToScope)($scope)
      $scope.$on('$change', handleDetect)
      function mapStateToScope(state) {
        return {
          stateRedux: state
        }
      }
      $scope.reactState = {
        helpers: Service,
        HttpPromise: HttpPromiseAndLocalStorage,
        API_ENDPOINT: API_ENDPOINT
      }

      $scope.joinBeta = (company) => {
        Http(
          {
            method: 'post',
            api: '/v1/migrate2020/join-beta',
            data: {
              companyId: company._id
            }
          },
          (retVal) => {
            location.reload()
          },
          (err) => {
            console.error(err)
          }
        )
      }
      $scope.leaveBeta = (company) => {
        Http(
          {
            method: 'post',
            api: '/v1/migrate2020/leave-beta',
            data: {
              companyId: company._id
            }
          },
          (retVal) => {
            location.reload()
          },
          (err) => {
            console.error(err)
          }
        )
      }

      $scope.goToClientPortal = function (clientId) {
        $window.localStorage.pr_client_id = clientId
        $ngRedux.dispatch(setCurrentClient($scope.agentData.clients.find((x) => x._id === clientId)))
        $state.go('business.run.overview')
      }
      $scope.lessActiveEmployee = (openModal = false) => {
        const agentCompany = $scope.agentData.clients.find((x) => x._id === $scope.agentData?.agent?._id)
        const seats = agentCompany?.subscriptionStatus?.webapp?.seats || 0
        $scope.openModal = {
          open: false
        }
        if (seats < $scope.allActiveEmployees) {
          $scope.missingEmployee = $scope.allActiveEmployees - seats
          if (openModal) {
            $scope.openModal = {
              open: true,
              value: $scope.allActiveEmployees - seats
            }
          }
          return true
        } else {
          $scope.missingEmployee = 0
          return false
        }
      }

      const reloadClients = (clCaps) => {
        $scope.agentData.clients = $scope.agentData.clients.map((_c, index) => {
          let clientCaps = _c.clientCaps
          let subscriptionStatus = _c.subscriptionStatus
          if (index === 0) {
            clientCaps = clCaps
            subscriptionStatus.webapp = {
              ...subscriptionStatus.webapp,
              code: 'AGENT_SUBSCRIPTION_NEED_MORE_SEATS'
            }
          }
          if (clCaps.some((_cc) => _cc.clientId === _c._id)) {
            subscriptionStatus.webapp = {
              ...subscriptionStatus.webapp,
              status: 'active'
            }
            return { ..._c, isUnderAgentSubscription: true, clientCaps, subscriptionStatus }
          } else {
            subscriptionStatus.webapp = {
              ...subscriptionStatus.webapp,
              status: null
            }
            return { ..._c, isUnderAgentSubscription: false, clientCaps, subscriptionStatus }
          }
        })
        $ngRedux.dispatch(setSubscriptionState($scope.agentData.clients))
        $scope.agentIndex = $scope.agentData.clients.findIndex((x) => x._id === $scope.agentData?.agent?._id)
        $scope.lessActiveEmployee(true)
      }

      $scope.confirmPopup = (data, onSuccess, onError) => {
        CompanyAction.update(
          { clientCaps: data },
          (res) => {
            onSuccess()
            reloadClients(data)
          },
          onError
        )
      }

      $scope.openModal = {
        open: false,
        value: 0
      }

      $scope.setAgentData = (data) => {
        $scope.$emit('setAgentData', data)
      }
      if (GLOBALS.testConfig && GLOBALS.testConfig.companyId) {
        $scope.goToClientPortal(GLOBALS.testConfig.companyId)
      }
      $ngRedux.connect((state) => {
        const noSubClients = (state.subscription?.listAgentClient || []).filter(
          (client) => client?.subscriptionStatus?.webapp?.status !== 'active'
        )
        return {
          hasNoSubClients: noSubClients.length > 0
        }
      })($scope)

      $scope.openInviteClient = (isOpen) => {
        $ngRedux.dispatch(setDisplayInviteClient(isOpen))
      }
      $scope.openAddSeats = (isOpen) => {
        $ngRedux.dispatch(setEmployeeMissing($scope.missingEmployee))
        $ngRedux.dispatch(setDisplayAddSeats(isOpen))
      }

      $scope.onNavigateToBulkImport = () => {
        $state.go('agent.import.business')
      }

      $scope.openInvitationModal = AgentClientInvitationModal.openInvitationModal

      $scope.openImportClientsModal = function () {
        Service.modal(
          'agent/importClientsModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.cancel = function () {
                $uibModalInstance.dismiss()
              }

              $scope.downloadExcelTemplate = () => {
                document.getElementById('iframe_for_download').src =
                  'https://s3.amazonaws.com/bookipi.com/downloadable-files/client-template.xlsx'
              }

              $scope.saveBulkList = function () {
                // Http({
                // 	method : "post",
                // 	api: "/v1/bulk-import-clients/",
                // 	data: {
                // 		// importedData
                // 	},
                // 	disableErrorMsg : true
                // }, (res) => {
                // 	console.log(res);
                // }, ( err ) =>{
                // 	console.log("404 error");
                // 	console.log(err);
                // 	// redirect 404 page
                // });
              }

              $scope.uploadItemList = function (file) {
                if (file) {
                  var r = new FileReader()
                  r.onload = function (e) {
                    var contents = e.target.result
                    var importedData = Service.CSVToArray(contents)
                    if (importedData[0][0] && importedData[0][0].toLowerCase() == 'name') {
                      importedData.splice(0, 1)
                    }
                    var clientList = []

                    for (var index = 0; index < importedData.length; index++) {
                      if (importedData[index][0]) {
                        clientList.push(importedData[index])
                      }
                    }

                    $scope.$apply(function () {
                      $rootScope.importedData = clientList
                    })
                  }

                  r.readAsText(file)
                }
              }

              $scope.$watch('importFile', function (nv, ov) {
                if ($rootScope.importFile) {
                  var allowedExtensions = /(\.csv)$/i
                  if (!allowedExtensions.exec($rootScope.importFile.name)) {
                    Service.alert('', 'Please upload file having extensions .csv only.', 'danger')
                    $rootScope.importFile = ''
                    return false
                  } else {
                    $scope.uploadItemList($rootScope.importFile)
                    $rootScope.importFile = ''
                  }
                }
              })
            }
          ],
          { size: 'md' }
        )
      }

      $scope.hideDemoAccount = () => {
        CompanyAction.agentUpdate({ 'agd.d_h': true })
      }

      $scope.hideMyAccount = () => {
        CompanyAction.agentUpdate({ 'agd.ma_h': 1 })
        Service.alert('You can make your account visible in Settings > Client page.')
      }

      $scope.changeClientEmail = (client) => {
        Service.modal(
          'agent/changeClientEmailModal.html',
          [
            '$scope',
            '$uibModalInstance',
            function ($scope, $uibModalInstance) {
              $scope.client = client
              $scope.loading = false
              $scope.cancel = () => {
                $uibModalInstance.dismiss()
              }

              $scope.sendEmail = () => {
                if (!$scope.clientEmail || !Service.validateEmail($scope.clientEmail)) {
                  Service.alert('Error', 'The email you have provided is invalid.', 'error')
                  return
                }

                $scope.loading = true

                Http(
                  {
                    method: 'post',
                    api: '/v1/user/change-email',
                    data: {
                      newEmail: $scope.clientEmail,
                      clientId: client._id,
                      fromAgent: true
                    }
                  },
                  (_) => {
                    $scope.cancel()
                    Service.infoPopup({
                      title: 'Change email address',
                      contents: `An email has been sent to ${$scope.clientEmail}. Please check your inbox and follow the steps in the email to verify your new email address.`
                    })
                  },
                  (_) => {
                    $scope.loading = false
                  }
                )
              }
            }
          ],
          { size: 'sm' }
        )
      }

      $scope.removeClient = (client) => {
        if (client.payRun && client.payRun.filter((x) => x).length === 0) {
          Service.confirm({
            title: 'Confirm deletion',
            contents: `By clicking 'Continue', you are confirming that you have the authority to delete this client's Payroller account.<br><br>
            If you want to remove them from the agent portal, please follow these <a href='https://intercom.help/payroller-app/en/articles/5765593-unlink-your-client-from-your-agent-portal' target=_new>instructions.</a>`,
            confirmText: 'Confirm',
            cancelText: 'Cancel',
            confirmCallback: function () {
              if (!$scope.loading) {
                $scope.loading = true
                Http(
                  {
                    method: 'post',
                    api: '/v1/agent/remove-client/' + client._id
                  },
                  (res) => {
                    $scope.loading = false
                    Service.alert('Success!', 'This account has been terminated successfully!', 'success')
                    const index = Service.findIndexByKeyValue($scope.agentData.clients, '_id', client._id)
                    $scope.agentData.clients.splice(index, 1)
                    $scope.agentIndex = $scope.agentData.clients.findIndex(
                      (x) => x._id === $scope.agentData?.agent?._id
                    )
                  },
                  (err) => {
                    $scope.loading = false
                  }
                )
              }
            },
            cancelCallback: () => {}
          })
        } else {
          Service.alert(
            'Error!',
            "You cannot delete your client's account if a pay run exists. To delete the account, you need to remove any existing pay runs or ask your client to terminate the account in the settings.",
            'error'
          )
        }
      }
    }
  )
