"use strict";
exports.__esModule = true;
exports.BPWebAppActions = exports.BPMobileAppActions = void 0;
var BPMobileAppActions;
(function (BPMobileAppActions) {
    BPMobileAppActions["STORE_AUTH"] = "storeAuth";
    BPMobileAppActions["OPEN_LINKS"] = "openLinks";
    BPMobileAppActions["LOGOUT"] = "logout";
    BPMobileAppActions["SYNC_APPLE_CALENDAR"] = "syncAppleCalendar";
    BPMobileAppActions["UNSYNC_APPLE_CALENDAR"] = "unsyncAppleCalendar";
    BPMobileAppActions["CREATE_CALENDAR_EVENT"] = "createCalendarEvent";
})(BPMobileAppActions || (BPMobileAppActions = {}));
exports.BPMobileAppActions = BPMobileAppActions;
var BPWebAppActions;
(function (BPWebAppActions) {
    BPWebAppActions["REDIRECTION"] = "redirection";
    BPWebAppActions["SET_STORAGE"] = "set_storage";
    BPWebAppActions["MODAL"] = "modal";
    BPWebAppActions["ALERT"] = "alert";
    BPWebAppActions["AUTO_CLOCK_OUT"] = "auto_clock_out";
    BPWebAppActions["DELETE_TIMESHEET_CLOCKOUT"] = "delete_timesheet_clock_out";
})(BPWebAppActions || (BPWebAppActions = {}));
exports.BPWebAppActions = BPWebAppActions;
